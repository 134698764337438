<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="700px"
        min-width="600px"
        max-width="700px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addPaymentTerm') : $t('message.updatePaymentTerm') + ": " + paymentTermName }}</span>
                <HelpPopUpV2 help-page-component="AddPaymentTerm" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="paymentTermForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.formalPaymentTerm') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-textarea
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="Paymentterm__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    rows="2"
                                    solo
                                    @change="Paymentterm__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.nonStandard') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Paymentterm__non_standard"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-select
                                    :rules="[...validationRules.required]"
                                    :items="types"
                                    autocomplete="off"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Paymentterm__model"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.whSalesOnly') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Paymentterm__localsales_only"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.grossMarginEffect') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-select
                                    :items="grossMarginEffects"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    item-text="title"
                                    v-model="Paymentterm__grossmargineffect_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.toBePaidOn') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                        :placeholder="capitalize($t('message.days'))"
                                        :rules="[...validationRules.number, ...validationRules.minimumZero]"
                                        :value="Paymentterm__payday"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Paymentterm__payday = $event"
                                />
                                <v-select
                                        :items="payon"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="Paymentterm__payon_id"
                                />
                                <v-select
                                        :items="payDateReferences"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Paydateref.id"
                                        item-text="Paydateref.title"
                                        solo
                                        v-model="Paymentterm__paydateref_id"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-divider class="mx-0 px-0"></v-divider>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.deposit') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <div class="d-flex flex-row align-center">
                                <v-checkbox
                                        :true-value="1"
                                        class="mt-0"
                                        dense
                                        hide-details="auto"
                                        v-model="Paymentterm__dprequired"
                                />
                                <v-text-field
                                        :placeholder="$t('message.pct')"
                                        :rules="[...validationRules.number, ...validationRules.minimumZero]"
                                        :value="Paymentterm__amountinpercent"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Paymentterm__amountinpercent = $event"
                                />
                                <span class="ml-1 mr-3 font-lg">%</span>
                            </div>
                            <div class="d-flex flex-row align-center mt-2">
                                <v-text-field
                                        :placeholder="capitalize($t('message.days'))"
                                        :rules="[...validationRules.number, ...validationRules.minimumZero]"
                                        :value="Paymentterm__dppayday"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Paymentterm__dppayday = $event"
                                />
                                <v-select
                                        :items="payon"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="Paymentterm__dppayon_id"
                                />
                                <v-select
                                        :items="payDateReferences"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Paydateref.id"
                                        item-text="Paydateref.title"
                                        solo
                                        v-model="Paymentterm__dppaydateref_id"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-divider class="mx-0 px-0"></v-divider>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.otsTerm') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-combobox
                                    :items="defaultPaymentStatuses"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="OtsTerm__default"
                                    autocomplete="off"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="PaymentStatus.title"
                                    item-value="PaymentStatus.id"
                                    return-object
                                    solo
                                    @change="updateOtsTerm('OtsTerm__default',$event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="Paymentterm__dprequired == 1">
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.otsDpPaidTerm') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-combobox
                                    :items="dpPaidPaymentStatuses"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="OtsTerm__dppaid"
                                    autocomplete="off"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="PaymentStatus.title"
                                    item-value="PaymentStatus.id"
                                    return-object
                                    solo
                                    @change="updateOtsTerm('OtsTerm__dppaid',$event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.otsFullyPaidTerm') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-combobox
                                    :items="fullyPaidPaymentStatuses"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="OtsTerm__fullypaid"
                                    autocomplete="off"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="PaymentStatus.title"
                                    item-value="PaymentStatus.id"
                                    return-object
                                    solo
                                    @change="updateOtsTerm('OtsTerm__fullypaid',$event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs3 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.lcBased') }}</v-flex>
                        <v-flex xs9 lg9 md7 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Paymentterm__lc_payment_term"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addPaymentTerm()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="savePaymentTerm()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {capitalize} from "Helpers/helpers";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddPaymentTerm",
    components: { HelpPopUpV2 },
    props: ['paymentTermId','paymentTermName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            paymentStatuses: [],
            payon: [
                {value: 1, text: this.$t('message.before')},
                {value: 2, text: this.$t('message.after')},
                {value: 3, text: this.$t('message.from')},
            ],
            // rules: {
            //     dpPaidStatus: v => !!v || this.$t('message.required'),
            //     fullyPaidStatus: v => !!v || this.$t('message.required'),
            //     otsTerm: v => !!v || this.$t('message.required'),
            //     title: v => !!v || this.$t('message.required'),
            //     type: v => !!v || this.$t('message.required')
            // },
            types: [
                {value: 'c', text: this.$t('message.buyer')},
                {value: 'p', text: this.$t('message.supplier')},
            ],
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('paymentterm',{
            OtsTerm__default: 'OtsTerm.default',
            OtsTerm__dppaid: 'OtsTerm.dppaid',
            OtsTerm__fullypaid: 'OtsTerm.fullypaid',
            Paymentterm__amountinpercent: 'Paymentterm.amountinpercent',
            Paymentterm__dppaid_id: 'Paymentterm.dppaid_id',
            Paymentterm__dppaydateref_id: 'Paymentterm.dppaydateref_id',
            Paymentterm__dppayday: 'Paymentterm.dppayday',
            Paymentterm__dppayon_id: 'Paymentterm.dppayon_id',
            Paymentterm__dprequired: 'Paymentterm.dprequired',
            Paymentterm__fullypaid_id: 'Paymentterm.fullypaid_id',
            Paymentterm__grossmargineffect_id: 'Paymentterm.grossmargineffect_id',
            Paymentterm__id: 'Paymentterm.id',
            Paymentterm__lc_payment_term: 'Paymentterm.lc_payment_term',
            Paymentterm__localsales_only: 'Paymentterm.localsales_only',
            Paymentterm__model: 'Paymentterm.model',
            Paymentterm__non_standard: 'Paymentterm.non_standard',
            Paymentterm__paydateref_id: 'Paymentterm.paydateref_id',
            Paymentterm__payday: 'Paymentterm.payday',
            Paymentterm__paymentterm_id: 'Paymentterm.paymentterm_id',
            Paymentterm__payon_id: 'Paymentterm.payon_id',
            Paymentterm__title: 'Paymentterm.title'
        },'statePrefix'),
        ...mapGetters([
            'grossMarginEffects',
            'payDateReferences',
            'validationRules'
        ]),
        capitalize: () => capitalize,
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        defaultPaymentStatuses() {
            let terms = []
            this.paymentStatuses.forEach(term => {
                if( term.PaymentStatus.type == 1 ) {
                    terms.push(term)
                }
            })
            return terms
        },
        dpPaidPaymentStatuses() {
            let terms = []
            this.paymentStatuses.forEach(term => {
                if( term.PaymentStatus.type == 2 ) {
                    terms.push(term)
                }
            })
            return terms
        },
        fullyPaidPaymentStatuses() {
            let terms = []
            this.paymentStatuses.forEach(term => {
                if( term.PaymentStatus.type == 3 ) {
                    terms.push(term)
                }
            })
            return terms
        },
    },
    methods: {
        ...mapActions('paymentterm',{
            createPaymentTerm: 'createPaymentTerm',
            getPaymentStatuses: 'getPaymentStatuses',
            getCleanedPaymentStatuses: 'getCleanedPaymentStatuses',
            getPaymentTermToUpdateById: 'getPaymentTermToUpdateById',
            resetPaymentTermToUpdate: 'resetPaymentTermToUpdate',
            resetCurrentState: 'resetCurrentState',
            updatePaymentTerm: 'updatePaymentTerm'
        }),
        addPaymentTerm () {
            if( this.$refs.paymentTermForm.validate() ) {
                this.loading.add = true
                this.createPaymentTerm()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.paymentTermAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.paymentTermNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.paymentTermNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.paymentTermNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        savePaymentTerm () {
            if( this.$refs.paymentTermForm.validate() ) {
                this.loading.save = true
                this.updatePaymentTerm()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.paymentTermUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.paymentTermNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.paymentTermNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.paymentTermNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        updateOtsTerm(field, value) {
            if(typeof value === 'object'){
                switch(field){
                    case 'OtsTerm__default':
                        this.Paymentterm__paymentterm_id = value.PaymentStatus.id
                        break
                    case 'OtsTerm__dppaid':
                        this.Paymentterm__dppaid_id = value.PaymentStatus.id
                        break
                    case 'OtsTerm__fullypaid':
                        this.Paymentterm__fullypaid_id = value.PaymentStatus.id
                        break
                }
            } else {
                //new value
                switch(field){
                    case 'OtsTerm__default':
                        this.Paymentterm__paymentterm_id = value
                        break
                    case 'OtsTerm__dppaid':
                        this.Paymentterm__dppaid_id = value
                        break
                    case 'OtsTerm__fullypaid':
                        this.Paymentterm__fullypaid_id = value
                        break
                }
            }
        }
    },
    watch: {
        dialog (value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getPaymentTermToUpdateById(this.paymentTermId)
                        .then(()=>{
                            this.loading.get = false
                            if(this.Paymentterm__paymentterm_id != null){
                                let term = this.paymentStatuses.find(status => status.PaymentStatus.id == this.Paymentterm__paymentterm_id)
                                this.OtsTerm__default = term.PaymentStatus.title
                            }
                            if(this.Paymentterm__dppaid_id != null){
                                let term = this.paymentStatuses.find(status => status.PaymentStatus.id == this.Paymentterm__dppaid_id)
                                this.OtsTerm__dppaid = term.PaymentStatus.title
                            }
                            if(this.Paymentterm__fullypaid_id != null){
                                let term = this.paymentStatuses.find(status => status.PaymentStatus.id == this.Paymentterm__fullypaid_id)
                                this.OtsTerm__fullypaid = term.PaymentStatus.title
                            }
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetPaymentTermToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    created() {
        this.getCleanedPaymentStatuses()
            .then( data => {
                this.paymentStatuses = data
            })
    }
}
</script>

<style scoped>

</style>